var consts = {
    // MainUrl: "http://localhost:3000",
    // BackendUrl: "http://localhost:3500/apzkyc",
    // successurl: "http://localhost:3000/check",
    // failureUrl: "http://localhost:3000/check",
    // socketUrl: "http://localhost:8000/",

    socketUrl: "https://stage.apzkyc.com",
    MainUrl: "https://stage.apzkyc.com/",
    BackendUrl: "https://stage.apzkyc.com/apzkyc",
    successurl: "https://stage.apzkyc.com/check",
    failureUrl: "https://stage.apzkyc.com/check",

    // socketUrl: "https://apzkyc.com",
    // MainUrl: "https://apzkyc.com/",
    // BackendUrl: "https://apzkyc.com/apzkyc",
    // successurl: "https://apzkyc.com/check",
    // failureUrl: "https://apzkyc.com/check",
}
export default consts;